import {
    accountMessages, deliveryMessages, generalMessages, paymentMessages, serviceMessages,
} from './tk.messages';
import render from './tk.render';

export enum NotificationType {
    SUCCESS = 'Success',
    WARNING = 'Warning',
    ERROR = 'Error',
    INFO = 'Info',
}

interface MessageResponse {
    pluginId: string;
    paymentType: string;
}

const mapMessageCodeToText = (response: MessageResponse, messages: Record<string, Message>) => {
    let textMessage;
    if (!response.pluginId) return '';
    if (response.pluginId === 'paymentType') {
        const paymentMessages: Record<number, string> = {
            0: messages.messagePaymentTypeGeneral.text,
            1: messages.messagePaymentTypeCreditCard.text,
            2: messages.messagePaymentTypeInvoice.text,
            5: messages.messagePaymentTypeInAdvance.text,
            6: messages.messagePaymentTypeOnDelivery.text,
        };
        textMessage = paymentMessages[Number(response.paymentType) || 0];
    } else if (response.pluginId === 'deliveryType') {
        textMessage = messages.messageDeliveryTypeGeneral.text;
    } else if (response.pluginId === 'checkoutAdressChange') {
        textMessage = messages.messageChangeSuccess.text;
    }
    return textMessage;
};

const getConsoleStyle = (backgroundColor: string, textColor: string = 'white') => [
    `background: ${backgroundColor}`,
    `color: ${textColor}`,
    'display: block',
].join(';');

const logToConsole = (message: string, type: NotificationType) => {
    const consoleHandler: Record<NotificationType, () => void> = {
        [NotificationType.SUCCESS](): void {
            // eslint-disable-next-line no-console
            console.info(`%c ${message}`, getConsoleStyle('green'));
        },
        [NotificationType.WARNING](): void {
            // eslint-disable-next-line no-console
            console.warn(`%c ${message}`, getConsoleStyle('yellow', 'black'));
        },
        [NotificationType.ERROR](): void {
            // eslint-disable-next-line no-console
            console.log(`%c ${message}`, getConsoleStyle('red'));
        },
        [NotificationType.INFO](): void {
            // eslint-disable-next-line no-console
            console.debug(`%c ${message}`, getConsoleStyle('blue'));
        },
    };

    consoleHandler[type]();
};

const renderMessageContainer = (parentElement: HTMLElement) => {
    let messageContainer = parentElement.querySelector<HTMLElement>('[data-tk-message-container]');
    if (!messageContainer) {
        messageContainer = render<HTMLElement>(
            '<div class="tk-message__container" data-tk-message-container></div>',
        );
        parentElement.insertAdjacentElement('beforeend', messageContainer);
    }

    return messageContainer;
};

export const sendNotification = (
    type: NotificationType,
    message?: string,
    wait: number = 2.5,
    options: { consoleMessage?: string } = {},
) => {
    if (!message) return;
    const dialogs = document.querySelectorAll<HTMLDialogElement>('dialog[open]');
    let messageContainer: HTMLElement;

    if (dialogs.length === 0) {
        messageContainer = renderMessageContainer(document.body);
    } else {
        const lastDialog = Array.from(dialogs).at(-1);
        if (!lastDialog) {
            messageContainer = renderMessageContainer(document.body);
        } else {
            messageContainer = renderMessageContainer(lastDialog);
        }
    }

    const toast = render(`
        <div class="tk-message tk-message--toast tk-message--${type.toLowerCase()}">
            <span class="tk-message__icon"><i class="tk-icon-${type.toLowerCase()}"></i></span>
            <span class="tk-message__label">
                ${message}
                ${serviceMessages.messageServiceInfo.text !== '' ? serviceMessages.messageServiceInfo.text : ''}
            </span>
            <button type="button" class="tk-button tk-button--only-icon tk-message__close" data-tk-message-close>
                <span class="tk-button__icon"><i class="tk-icon-cross"></i></span>
            </button>
        </div>
    `);

    toast.style.animation = `fadein .5s, fadeout .5s ${wait}s`;
    toast.classList.add('tk-message--show');

    messageContainer.appendChild(toast);

    toast.addEventListener('animationend', (event) => {
        if ((event as AnimationEvent).animationName !== 'fadeout') return;
        messageContainer.removeChild(toast);
    });

    const close = toast.querySelector('[data-tk-message-close]') || undefined;
    close?.addEventListener('click', () => {
        toast.remove();
    });

    options.consoleMessage
        ? logToConsole(options.consoleMessage, type)
        : logToConsole(message, type);
};

export const sendNotificationByItem = (item: Message, messageOverride?: string) => {
    const type = item.code.substring(0, 1);
    const message = messageOverride || item.text;

    const typeHandler: Record<string, () => void> = {
        1: () => sendNotification(NotificationType.SUCCESS, message, item.wait),
        2: () => sendNotification(NotificationType.WARNING, message, item.wait),
        3: () => sendNotification(NotificationType.ERROR, message, item.wait),
        4: () => sendNotification(NotificationType.INFO, message, item.wait),
    };

    typeHandler[type]();
};

export const sendNotificationByItems = (items: Message[], response: MessageResponse) => {
    const messages = {
        ...generalMessages, ...accountMessages, ...paymentMessages, ...deliveryMessages,
    };
    items.forEach((item) => {
        sendNotificationByItem(item, mapMessageCodeToText(response, messages));
    });
};
